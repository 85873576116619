import { pointTagService } from '@/api';
import { PointTagResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { OsTags } from '@/components';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
@Component({
  components: { OsTags }
})
export default class AddPointTag extends mixins(DialogMixin) {
  @Prop({
    required: false,
    default: () => {
      return null;
    },
    type: Object
  })
  public pointTag!: PointTagResource | null;

  public pointTagForm: {
    name: string;
    companyId: number;
    tags: Array<string>;
  } = {
    companyId: 0,
    tags: [],
    name: ''
  };

  public resourceFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('tag.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          pointTagService
            .checkTagName(value, this.pointTagForm.companyId, this.pointTag?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.pointTagNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    tags: [
      {
        required: true,
        validator: this.tagValidator,
        trigger: 'blur'
      }
    ]
  };

  public inputTagError = '';

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.pointTagForm.companyId = Number(this.$route.query.companyId);
    if (!this.pointTag) {
      this.operationType = 'add';
      this.title = 'pointTag.addPointTag';
      return;
    }
    this.operationType = 'edit';
    this.title = 'pointTag.editPointTag';
    this.$nextTick(() => {
      Object.assign(this.pointTagForm, this.pointTag);
      this.pointTagForm.tags = this.pointTag!.options.split(',');
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.pointTagForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.pointTagForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const form = { ...this.pointTagForm, options: this.pointTagForm.tags.join(',') } as any;
        if (this.operationType === 'add') {
          await pointTagService.post(form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await pointTagService.put(form);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public handleTagChanged(tags: Array<string>): void {
    this.pointTagForm.tags = tags;
  }

  private tagValidator(rule: any, value: Array<string>, callback: Function): void {
    if (!value || value.length === 0) {
      callback(new Error(translation('tag.inputTag')));
      return;
    }
    if (this.inputTagError) {
      callback(new Error(translation('tag.repeat')));
      return;
    }
    callback();
  }
}
